import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import appConfig from 'config/app.config';
import Stats from './stats';

class StatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			stats: []
		};
		this.unsubscribeStats = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.subscribeToStats();
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeStats !== null) this.unsubscribeStats();
	}

	subscribeToStats = () => {
		if (this.unsubscribeStats !== null) this.unsubscribeStats();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeStats = db.collection(appConfig.statsDbName).onSnapshot((docs) => {
				let stats = [];
				docs.forEach((doc) => {stats.push({id: doc.id, ...doc.data()});});
				this.setState({stats}, () => {resolve({status: 'ok'});});
			}, (error) => {
				console.error('could not get stats: ', error);
				resolve({status: 'error', error: error});
			});
		});
	}


	/**
	 * Render component
	 */
	render = () => {	
		return (
			<Stats stats={this.state.stats} handleLogout={this.props.handleLogout} />
		);
	}
}

StatsController.propTypes = {
	handleLogout: PropTypes.func.isRequired
};

export default StatsController;