/* TEMPLATE
{
	id: '',
	type: 'sort',
	roomId: 1,
	challengeNumber: 1,
	question: {
		text: ''
	},
	boxes: [
		{id: '', name: ''},
		{id: '', name: ''},
	],
	items: [
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''}
	]
},
*/
export interface ISortQuestion {
	text: string;
}
export interface ISortBox {
	id: string;
	name: string;
}
export interface ISortItem {
	id: string;
	name: string;
	isPlaced: boolean;
	correctBoxId: string;
}
export interface ISortData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: ISortQuestion;
	boxes: ISortBox[];
	items: ISortItem[];
	completed?: boolean;
	errors?: number;
}

const sortData: ISortData[] = [
	{
		id: 'sort-1',
		type: 'sort',
		roomId: 1,
		challengeNumber: 3,
		question: {
			text:
				'Grundloven fastsætter magtens tredeling - altså at der er en lovgivende, en udøvende og en dømmende magt. Sorter følgende opgaver og udsagn.',
		},
		boxes: [
			{ id: 'a', name: 'Lovgivende' },
			{ id: 'b', name: 'Udøvende' },
			{ id: 'c', name: 'Dømmende' },
		],
		items: [
			{
				id: '1',
				name: 'Vedtager landets love',
				isPlaced: false,
				correctBoxId: 'a',
			},
			// {
			// 	id: '2',
			// 	name: 'Var oprindeligt kongen',
			// 	isPlaced: false,
			// 	correctBoxId: 'b',
			// },
			{
				id: '3',
				name: 'Afgør om loven er overtrådt',
				isPlaced: false,
				correctBoxId: 'c',
			},
			{
				id: '4',
				name: 'Regerer landet efter de love, der gælder',
				isPlaced: false,
				correctBoxId: 'b',
			},
			{
				id: '5',
				name: 'Vedtager statens budget',
				isPlaced: false,
				correctBoxId: 'a',
			}
		],
	},
	{
		id: 'sort-2',
		type: 'sort',
		roomId: 1,
		challengeNumber: 7,
		question: {
			text: 'Grundloven sikrer forskellige frihedsrettigheder. Sorter følgende i den korrekte type af rettighed.',
		},
		boxes: [
			{ id: 'a', name: 'Personlige' },
			{ id: 'b', name: 'Økonomiske' },
			{ id: 'c', name: 'Politiske' }
		],
		items: [
			{
				id: '1',
				name: 'Retten til at forsamle sig',
				isPlaced: false,
				correctBoxId: 'c',
			},
			{
				id: '2',
				name: 'Retten til at ytre sig',
				isPlaced: false,
				correctBoxId: 'c',
			},
			{
				id: '3',
				name: 'Den private ejendomsret',
				isPlaced: false,
				correctBoxId: 'a',
			},
			{
				id: '4',
				name: 'Retten til at vælge religion',
				isPlaced: false,
				correctBoxId: 'a',
			},
			{
				id: '5',
				name: 'Retten til at danne foreninger',
				isPlaced: false,
				correctBoxId: 'c',
			},
			{
				id: '6',
				name: 'Retten til at drive erhverv (indenfor lovens rammer)',
				isPlaced: false,
				correctBoxId: 'b',
			},
		],
	}
];

export { sortData };
