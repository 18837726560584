export interface IOrderQuestion {
	text: string;
}
export interface IOrderBox {
	id: string;
	name: number;
	placedItemId: string | null;
	correctItemId: string;
}

export interface IOrderItem {
	id: string;
	name: string;
}
export interface IOrderData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IOrderQuestion;
	boxes: IOrderBox[];
	items: IOrderItem[];
	completed?: boolean;
	errors?: number;
}

const orderData: IOrderData[] = [
	{
		id: 'order-1',
		type: 'order',
		roomId: 1,
		challengeNumber: 9,
		question: {
			text: 'Hvad skal der til for at vedtage en lov? Sæt nedenstående i den rigtige rækkefølge.',
		},
		boxes: [
			{id: '1', name: 1, placedItemId: null, correctItemId: '1'},
			{id: '2', name: 2, placedItemId: null, correctItemId: '2'},
			{id: '3', name: 3, placedItemId: null, correctItemId: '3'},
			{id: '4', name: 4, placedItemId: null, correctItemId: '4'},
			{id: '5', name: 5, placedItemId: null, correctItemId: '5'},
		],
		items: [
			{id: '1', name: 'Lovforslag fremsættes enten af en minister eller af et medlem af Folketinget'},
			{id: '2', name: 'Behandling i Folketinget mindst 3 gange'},
			{id: '3', name: '1/3 af Folketinget kan begære Folkeafstemning'},
			{id: '4', name: 'Stadfæstelse ved ministers underskrift'},
			{id: '5', name: 'Kundgørelse (i Lovtidende)'},
		]
	}
];

export { orderData };
