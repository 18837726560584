// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react';
import useStateCallback from 'helpers/use-state-callback';
import appConfig from 'config/app.config';
import { shuffleArray } from 'helpers/array-helper';
import {
	singlePairPoints,
	multiplePairsPoints,
	streakPoints,
} from 'data/points-data';
import { getIconType } from 'helpers/points-helper';
import Pairs from './pairs';
import { IResolveStatus } from '../challenge-controller';
import { IChallenge, IPlayerData } from 'components/game/game-controller';
import { IPairscolumnAItem } from 'data/pairs-data';

interface IProps {
	roomId: number;
	challengeData: IChallenge;
	playerData: IPlayerData;
	toggleStreakPopup: (
		showStreakPopup: boolean,
		challengeCompleted?: boolean | undefined,
		isLastChallengeInRoom?: boolean | undefined,
		newPointsTierUnlocked?: boolean | undefined
	) => void;
	completeChallenge: (
		challengeId: string,
		points: number,
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		playerChallenges: any, 
		playerStreaks: any,
		playerpoints: any
	) => Promise<null>;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
}

const PairsController: React.FC<IProps> = ({
	roomId,
	challengeData,
	playerData,
	toggleStreakPopup,
	updatePlayerData,
	completeChallenge,
}: IProps) => {
	const [pairsControllerState, setPairsControllerState] = useStateCallback({
		isLoading: true,
		isPaused: false,
		pairsData: null,
	});

	/**
	 * Load pairs & sync with player
	 */
	const loadChallenge = () => {
		/* Get pairs data */
		const parsedChallengeData = JSON.parse(JSON.stringify(challengeData));
		parsedChallengeData.completed = false;
		parsedChallengeData.errors = 0;

		/* Shuffle items in columns */
		parsedChallengeData.columnA = shuffleArray(parsedChallengeData.columnA);
		parsedChallengeData.columnB = shuffleArray(parsedChallengeData.columnB);

		/* Sync with player progress */
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.some((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})
		) {
			const playerChallengeData = playerData.challenges.filter((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				parsedChallengeData.completed = playerChallengeData.completed;
			}
			parsedChallengeData.errors = playerChallengeData.errors;
		}

		/* Update state */
		setPairsControllerState(
			{
				...pairsControllerState,
				isLoading: false,
				isPaused: parsedChallengeData.completed,
				pairsData: parsedChallengeData,
			},
			() => {
				/* Adjust body padding */
				const headerElement = document.getElementById('PairsHeader');
				const bodyElement = document.getElementById('PairsBody');
				if (headerElement && bodyElement) {
					const fontSize = 16 * (headerElement.clientWidth / 360);
					const tripleFontsize = 3 * fontSize;
					const headerHeight = headerElement.clientHeight + tripleFontsize;
					bodyElement.style.paddingTop = headerHeight + 'px';
				}
			}
		);
	};

	/**
	 * Select card
	 * @param {string} columnId
	 * @param {number} cardIndex
	 */
	const handleSelectCard = (columnId: string, cardIndex: number) => {
		if (pairsControllerState.isPaused) return;

		setPairsControllerState({ ...pairsControllerState, isPaused: true }, () => {
			const parsedPairsData = JSON.parse(
				JSON.stringify(pairsControllerState.pairsData)
			);

			/* Card is already connected - return */
			if (
				(columnId === 'columnA' &&
					parsedPairsData['columnA'][cardIndex].connectedTo !== null) ||
				(columnId === 'columnB' &&
					parsedPairsData['columnA'].some((card: IPairscolumnAItem) => {
						return (
							card.connectedTo === parsedPairsData['columnB'][cardIndex].id
						);
					}))
			) {
				setPairsControllerState({
					...pairsControllerState,
					isPaused: false,
					pairsData: parsedPairsData,
				});
				return;
			}

			/* Card is not connected - selected it */
			if (
				!parsedPairsData[columnId].some((card: IPairscolumnAItem) => {
					return card.isSelected === true;
				})
			) {
				/* No cards are selected in that column - select card */
				parsedPairsData[columnId][cardIndex].isSelected = true;
			} else {
				/* A card in that column is already selected */
				// const selectedCardIndex = pairsData[columnId].findIndex(
				// 	(card: IPairscolumnAItem) => {
				// 		return card.isSelected === true;
				// 	}
				// );
				// Refactor for IE
				let selectedCardIndex = -1;
				// eslint-disable-next-line array-callback-return
				parsedPairsData[columnId].some((card: IPairscolumnAItem, i: number) => {
					if (card.isSelected === true) {
						selectedCardIndex = i;
						return true;
					}
				});
				/* Different card: select new card instead */
				if (selectedCardIndex !== cardIndex) {
					parsedPairsData[columnId][selectedCardIndex].isSelected = false;
					parsedPairsData[columnId][selectedCardIndex].connectedTo = null;
					parsedPairsData[columnId][cardIndex].isSelected = true;
					/* Same card: deselect card */
				} else {
					parsedPairsData[columnId][cardIndex].isSelected = false;
					parsedPairsData[columnId][cardIndex].connectedTo = null;
				}
			}

			/* Check if two non-connected cards are selected */
			// const cardAIndex = pairsData['columnA'].findIndex(
			// 	(card: IPairscolumnAItem) => {
			// 		return card.isSelected === true;
			// 	}
			// );
			// Refactor for IE
			let cardAIndex = -1;
			// eslint-disable-next-line array-callback-return
			parsedPairsData['columnA'].some((card: IPairscolumnAItem, i: number) => {
				if (card.isSelected === true) {
					cardAIndex = i;
					return true;
				}
			});
			// const cardBIndex = pairsData['columnB'].findIndex(
			// 	(card: IPairscolumnAItem) => {
			// 		return card.isSelected === true;
			// 	}
			// );
			// Refactor for IE
			let cardBIndex = -1;
			// eslint-disable-next-line array-callback-return
			parsedPairsData['columnB'].some((card: IPairscolumnAItem, i: number) => {
				if (card.isSelected === true) {
					cardBIndex = i;
					return true;
				}
			});
			const twoCardsAreSelected = cardAIndex >= 0 && cardBIndex >= 0;

			/* Max 1 card selected - return */
			if (!twoCardsAreSelected) {
				setPairsControllerState({
					...pairsControllerState,
					isPaused: false,
					pairsData: parsedPairsData,
				});
				return;
			}

			/* Two cards selected  */
			/* Connect cards */
			// parsedPairsData['columnA'][cardAIndex].isSelected = false;
			parsedPairsData['columnA'][cardAIndex].connectedTo =
				parsedPairsData['columnB'][cardBIndex].id;
			// parsedPairsData['columnB'][cardBIndex].isSelected = false;

			/* Check if connection is correct */
			const isCorrectConnection =
				parsedPairsData['columnA'][cardAIndex].hasOwnProperty(
					'correctConnectionId'
				) &&
				parsedPairsData['columnA'][cardAIndex].connectedTo ===
					parsedPairsData['columnA'][cardAIndex].correctConnectionId;

			/* Increase number of errors if not correct */
			if (!isCorrectConnection) {
				parsedPairsData.errors = parsedPairsData.errors + 1;
			}
			/* Update game data */
			setPairsControllerState({
				...pairsControllerState,
				pairsData: parsedPairsData,
			});

			/* Player data - progress & errors */
			let playerChallenges: any[] = [];
			if (playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(playerData.challenges));
			}
			// const playerChallengeIndex = playerChallenges.findIndex(
			// 	(challenge: IChallenge) => {
			// 		return challenge.id === parsedPairsData.id;
			// 	}
			// );
			// Refactor for IE
			let playerChallengeIndex = -1;
			// eslint-disable-next-line array-callback-return
			playerChallenges.some((challenge: IChallenge, i: number) => {
				if (challenge.id === parsedPairsData.id) {
					playerChallengeIndex = i;
					return true;
				}
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: parsedPairsData.id,
					completed: false,
					errors: parsedPairsData.errors,
				});
			} else {
				playerChallenges[playerChallengeIndex].errors = parsedPairsData.errors;
			}

			/* Player data - points */
			const playerPoints = JSON.parse(JSON.stringify(playerData.points));

			/* Player data - streak */
			let streakUnlocked = false;
			let newPointsTierUnlocked = false;
			let playerStreaks: any = {};
			if (appConfig.useStreaks) {
				if (playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(playerData.streaks));
				}
				if (!playerStreaks.hasOwnProperty(`room${roomId.toString()}`)) {
					playerStreaks[`room${roomId.toString()}`] = 0;
				}
				if (
					playerStreaks[`room${roomId.toString()}`] !== -1 ||
					!appConfig.limitStreaksPerRoom
				) {
					if (isCorrectConnection) {
						playerStreaks[`room${roomId.toString()}`] =
							playerStreaks[`room${roomId.toString()}`] + 1;
					} else {
						playerStreaks[`room${roomId.toString()}`] = 0;
					}
					const roomStreak = parseInt(
						playerStreaks[`room${roomId.toString()}`]
					);
					if (roomStreak === streakPoints.streakMarker) {
						streakUnlocked = true;
						const prevPlayerPoints = playerPoints[`room${roomId.toString()}`];
						playerPoints[`room${roomId.toString()}`] =
							playerPoints[`room${roomId.toString()}`] +
							streakPoints.streakPoints;
						newPointsTierUnlocked =
							getIconType(prevPlayerPoints, roomId) !== 
							getIconType(playerPoints[`room${roomId.toString()}`], roomId);
						if (appConfig.limitStreaksPerRoom) {
							playerStreaks[`room${roomId.toString()}`] = -1;
						} else {
							playerStreaks[`room${roomId.toString()}`] = 0;
						}
					}
				}
			}

			/* Update player data */
			updatePlayerData({
				...playerData,
				challenges: playerChallenges,
				streaks: playerStreaks,
				points: playerPoints,
			}).then((response) => {
				if (response.status === 'ok') {
					let challengeIsComplete = true;
					if (isCorrectConnection) {
						/* Check if all pairs are connected correctly */
						parsedPairsData['columnA'].forEach((card: IPairscolumnAItem) => {
							if (
								card.hasOwnProperty('correctConnectionId') &&
								(card.connectedTo === null ||
									card.connectedTo !== card.correctConnectionId)
							) {
								challengeIsComplete = false;
							}
						});
					} else {
						/* Break connection */
						challengeIsComplete = false;
					}

					/* Update game data */
					parsedPairsData['columnA'][cardAIndex].isSelected = false;
					parsedPairsData['columnB'][cardBIndex].isSelected = false;
					if (!isCorrectConnection) {
						parsedPairsData['columnA'][cardAIndex].connectedTo = null;
					}
					/* Set animation timeout */
					setTimeout(() => {
						if (challengeIsComplete) {
							handleCompleteAllPairs(
								streakUnlocked,
								newPointsTierUnlocked,
								parsedPairsData,
								playerChallenges,
								playerStreaks,
								playerPoints
							);
						} else {
							setPairsControllerState({
								...pairsControllerState,
								isPaused: false,
								pairsData: parsedPairsData,
							});
							if (streakUnlocked) {
								toggleStreakPopup(true, false, false, newPointsTierUnlocked);
							}
						}
					}, 500);
				} else {
					console.log('error');
				}
			});
		});
	};

	/**
	 * Complete all pairs
	 * Calculate points, update player data, give feedback, update player data
	 * @param {bool} streakUnlocked
	 */
	const handleCompleteAllPairs = (
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		pairsData: any,
		playerChallenges: any,
		playerStreaks: any,
		playerPoints: any
	) => {
		const parsedChallengeData = { ...pairsData };
		parsedChallengeData.completed = true;

		const numberOfPairs = parsedChallengeData.columnA.filter(
			(card: IPairscolumnAItem) => {
				return card.hasOwnProperty('correctConnectionId');
			}
		).length;
		const pairsPoints =
			numberOfPairs === 1 ? singlePairPoints : multiplePairsPoints;
		let points = pairsPoints.minPoints;
		// const pointIndex = pairsPoints.pointLimits.findIndex((limit) => {
		// 	return parsedChallengeData.errors <= limit;
		// });
		// Refactor for IE
		let pointIndex = -1;
		// eslint-disable-next-line array-callback-return
		pairsPoints.pointLimits.some((limit, i) => {
			if (parsedChallengeData.errors <= limit) {
				pointIndex = i;
				return true;
			}
		});
		if (pointIndex >= 0) points = pairsPoints.pointValues[pointIndex];

		completeChallenge(
			parsedChallengeData.id,
			points,
			streakUnlocked,
			newPointsTierUnlocked,
			playerChallenges,
			playerStreaks,
			playerPoints
		).then(() => {
			setPairsControllerState({
				...pairsControllerState,
				pairsData: parsedChallengeData,
			});
		});
	};

	useEffect(() => {
		// Component mounted
		// Load challenge
		loadChallenge();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setPairsControllerState(
			{ ...pairsControllerState, isLoading: true, pairsData: null },
			() => {
				loadChallenge();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeData.id]);
	if (!pairsControllerState.isLoading && pairsControllerState.pairsData) {
		return (
			<Pairs
				isPaused={pairsControllerState.isPaused}
				pairsData={pairsControllerState.pairsData}
				handleSelectCard={handleSelectCard}
			/>
		);
	}
	return null;
};

export default PairsController;
