// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { generalUiTexts } from 'data/ui-texts';
import { renderMarkdown } from 'helpers/text-helper';
import { IRoom } from './room-overview-controller';
import { IGState, IGActions, useGlobal } from 'components/game/game-controller';
import { ReactComponent as DDlogo } from 'assets/images/logo-dd.svg';
import BackButton from 'components/layout/back-button';
import './room-overview.scss';

interface IProps {
	userCanSelectRoom: boolean;
	goToPage: (pageId: string | null, roomId?: number | null | undefined) => void;
	goToPreviousPage: () => void;
	rooms: IRoom[];
}

const RoomOverview: React.FC<IProps> = ({ userCanSelectRoom, goToPage, goToPreviousPage, rooms }: IProps) => {
	const [currentlang, toggleLang] = useGlobal<string, (currentLang: string) => void>(
		(state: IGState) => {
			return state.currentlang;
		},
	(actions: IGActions) => {
		return actions.toggleLang;
	}
	);

	const generalUiTextsLang = (currentlang === 'en' ? generalUiTexts.en : generalUiTexts.dk);

	return (
		<div id="RoomOverview" className="RoomOverview">
			<BackButton type={'overview'} goToPreviousPage={goToPreviousPage} />
			{/* Header */}
			<div className="RoomOverview-header">
				<DDlogo
					className="RoomOverview-ddLogo"
					onClick={() => {
						goToPreviousPage();
					}}
				/>
				<div
					className={`pointer RoomOverview-chooseLanguage lang--${currentlang === 'en' ? 'en' : 'dk'}`}
					onClick={() => {
						toggleLang(currentlang === 'en' ? 'en' : 'dk');
					}}
				/>
			</div>

			{/* Title */}
			<div className="RoomOverview-title">{generalUiTextsLang.gameName}</div>

			{/* Intro text */}
			<div className="RoomOverview-description">{renderMarkdown(generalUiTextsLang.introText)}</div>

			{/* Rooms */}
			<div className="RoomOverview-rooms">
				{rooms.map((room, i) => {
					if (!room.languageIds.includes(currentlang)) return null;
					return (
						<div
							key={i}
							className={`RoomOverview-Btn RoomOverview-Btn-${i} pointer`}
							onClick={() => {
								if (userCanSelectRoom) {
									goToPage('room', room.id);
								}
							}}
						>
							<span>{room.name}</span>
						</div>
					);
				})}
			</div>

			{/* Other games */}
			<div className="RoomOverview-otherGames">{renderMarkdown(generalUiTextsLang.otherGames)}</div>
			{/* <div className={'RoomOverview-otherGamesBtn'}> */}
			<a 
				className={'RoomOverview-otherGamesBtn'}
				href="https://domstolsdysten.dk"
				target="_blank" 
				rel="noopener noreferrer">
				{generalUiTextsLang.otherGamesBtn}
			</a>
			{/* </div> */}

		</div>
	);
};

export default RoomOverview;
