// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import { renderMarkdown } from 'helpers/text-helper';
import './order.scss';
import { IOrderData } from 'data/order-data';
import { useWindowSize } from 'helpers/utils';

interface IProps {
	isPaused: boolean;
	selectedItemId?: string;
	animation?: string;
	orderData: IOrderData;
	handleSelectItem: (itemId: string) => void;
	handleSelectBox: (boxIndex: number) => void;
}
const Order: React.FC<IProps> = ({
	isPaused,
	selectedItemId,
	animation,
	orderData,
	handleSelectItem,
	handleSelectBox,
}: IProps) => {
	const sizeCheck = useWindowSize();
	const [headerHeight, setHeaderHeight] = useState(80);

	useEffect(() => {
		const el = document.getElementById('OrderHeader');
		const height = el ? el.clientHeight + 15 : 80;
		setHeaderHeight(height);
	}, [sizeCheck]);
	return (
		<div
			className={
				'Order Order--' + orderData.id + (isPaused ? ' Order--paused' : '')
			}
		>
			{/* Order header */}
			<div
				id="OrderHeader"
				className="Order-header"
			>
				{/* Question */}
				<div className="Order-question">
					{renderMarkdown(orderData.question.text)}
				</div>

				{/* Boxes */}
				<div className="Order-boxes">
					{orderData.boxes.map((box, index) => {
						const text = box.name;
						let status = '';
						if (box.placedItemId === null) {
							status = '';
						} else {
							status =
								box.placedItemId === box.correctItemId ? 'correct' : 'wrong';
						}

						const className =
							'pointer Order-boxContainer Order-boxContainer--' +
							(index + 1) +
							' Order-boxContainer--' +
							status.toString();
						return (
							<div
								key={index}
								className={className}
								onClick={() => {
									if (!isPaused && box.placedItemId === null) {
										handleSelectBox(index);
									}
								}}
							>
								<div className="Order-boxText">{text}</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Order body */}
			<div
				id="OrderBody"
				className="Order-body"
				style={{ paddingTop: `${headerHeight}px` }}
			>
				{/* Items */}
				<div className="Order-items">
					{orderData.items.map((item, index) => {
						let className = 'pointer Order-item';
						// const boxIndex = orderData.boxes.findIndex((box) => {
						// 	return box.placedItemId === item.id;
						// });
						// Refactor for IE
						let boxIndex = -1;
						// eslint-disable-next-line array-callback-return
						orderData.boxes.some((box, i) => {
							if (box.placedItemId === item.id) {
								boxIndex = i;
								return true;
							}
						});

						const isPlaced = boxIndex >= 0;
						if (orderData.completed) {
							className = className + ' Order-item--placed';
						} else {
							if (item.id === selectedItemId) {
								if (animation) {
									className = className + ' Order-item--' + animation;
								} else {
									className = className + ' Order-item--selected';
								}
							} else {
								if (isPlaced) className = className + ' Order-item--placed';
							}
						}
						return (
							<div key={index} className="Order-itemWrap">
								<div
									className={className}
									onClick={() => {
										if (!isPaused && !isPlaced) handleSelectItem(item.id);
									}}
								>
									{renderMarkdown(item.name)}
								</div>
								{isPlaced && (
									<div className="Order-itemNumber">{boxIndex + 1}</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Order;
