// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react';
import appConfig from 'config/app.config';
import { shuffleArray } from 'helpers/array-helper';
import { quizPoints, streakPoints } from 'data/points-data';
import useStateCallback from 'helpers/use-state-callback';
import { getIconType } from 'helpers/points-helper';
import { IPlayerData, IChallenge } from 'components/game/game-controller';
// import {challengeUiTexts} from 'data/ui-texts';
import Quiz from './quiz';
import { IResolveStatus } from '../challenge-controller';
import { IQuizAnswer } from 'data/quizzes-data';

interface IProps {
	roomId: number;
	challengeData: IChallenge;
	playerData: IPlayerData;
	toggleStreakPopup: (
		showStreakPopup: boolean,
		challengeCompleted?: boolean | undefined,
		isLastChallengeInRoom?: boolean | undefined,
		newPointsTierUnlocked?: boolean | undefined
	) => void;
	completeChallenge: (
		challengeId: string,
		points: number,
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		playerChallenges: any,
		playerStreaks: any,
		playerpoints: any
	) => Promise<null>;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
}

const QuizController: React.FC<IProps> = ({
	roomId,
	challengeData,
	playerData,
	toggleStreakPopup,
	completeChallenge,
	updatePlayerData,
}: IProps) => {
	const [quizControllerState, setQuizControllerState] = useStateCallback({
		isLoading: true,
		isPaused: false,
		showImagePopup: false,
		quizData: null,
	});

	/**
	 * Load quiz & sync with player data
	 */
	const loadChallenge = () => {
		/* Get quiz data */
		const parsedChallengeData = JSON.parse(JSON.stringify(challengeData));
		parsedChallengeData.completed = false;

		/* Shuffle answers */
		parsedChallengeData.answers = (parsedChallengeData.question.shuffleAnswers === true
			? shuffleArray(parsedChallengeData.answers)
			: parsedChallengeData.answers
		);

		/* Sync with player progress */
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.some((challenge: IChallenge) => {
				return challenge.id === parsedChallengeData.id;
			})
		) {
			const playerChallengeData = playerData.challenges.filter((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				parsedChallengeData.completed = playerChallengeData.completed;
			}
			parsedChallengeData.answers.forEach((answer: IQuizAnswer) => {
				if (
					playerChallengeData.selectedAnswers &&
					playerChallengeData.selectedAnswers.indexOf(answer.id) >= 0
				) {
					answer.isSelected = true;
				}
			});
		}

		/* Update state */
		setQuizControllerState(
			{
				...quizControllerState,
				isLoading: false,
				isPaused: parsedChallengeData.completed,
				quizData: parsedChallengeData,
			},
			() => {
				/* Adjust body height */
				adjustBodyHeight();
			}
		);
	};

	/**
	 * Adjust height of body with respect to header
	 */
	const adjustBodyHeight = () => {
		const headerElement = document.getElementById('QuizHeader');
		const bodyElement = document.getElementById('QuizBody');
		if (headerElement && bodyElement) {
			const fontSize = 16 * (headerElement.clientWidth / 360);
			const halfFontsize = 0.5 * fontSize;
			const headerHeight = headerElement.clientHeight + halfFontsize;
			bodyElement.style.paddingTop = headerHeight + 'px';
		}
	};

	/**
	 * Show / hide image popup
	 */
	const toggleImagePopup = (showImagePopup: boolean) => {
		setQuizControllerState({
			...quizControllerState,
			showImagePopup: showImagePopup,
		});
	};

	/**
	 * Select answer
	 * @param {number} answerId
	 */
	const handleSelectAnswer = (answerId: number) => {
		if (quizControllerState.isPaused) return;

		setQuizControllerState({ ...quizControllerState, isPaused: true }, () => {
			/* Update quiz data */
			const quizData = JSON.parse(JSON.stringify(quizControllerState.quizData));
			// const answerIndex = quizData.answers.findIndex((answer: IQuizAnswer) => {
			// 	return answer.id === answerId;
			// });
			// Refactor for IE
			let answerIndex = -1;
			// eslint-disable-next-line array-callback-return
			quizData.answers.some((answer: IQuizAnswer, i: number) => {
				if (answer.id === answerId) {
					answerIndex = i;
					return true;
				}
			});
			quizData.answers[answerIndex].isSelected = true;
			setQuizControllerState({ ...quizControllerState, quizData: quizData });

			/* Player data - progress */
			let errors = 0;
			quizData.answers.forEach((answer: IQuizAnswer) => {
				if (!answer.isCorrect && answer.isSelected) errors = errors + 1;
			});

			const selectedAnswers: number[] = [];
			quizData.answers.forEach((answer: IQuizAnswer) => {
				if (answer.isSelected) selectedAnswers.push(answer.id);
			});

			let playerChallenges: any[] = [];
			if (playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(playerData.challenges));
			}
			// const playerChallengeIndex = playerChallenges.findIndex(
			// 	(challenge: IChallenge) => {
			// 		return challenge.id === quizData.id;
			// 	}
			// );
			// Refactor for IE
			let playerChallengeIndex = -1;
			// eslint-disable-next-line array-callback-return
			playerChallenges.some((challenge: IChallenge, i: number) => {
				if (challenge.id === quizData.id) {
					playerChallengeIndex = i;
					return true;
				}
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: quizData.id,
					completed: false,
					errors: errors,
					selectedAnswers: selectedAnswers,
				});
			} else {
				playerChallenges[
					playerChallengeIndex
				].selectedAnswers = selectedAnswers;
				playerChallenges[playerChallengeIndex].errors = errors;
			}

			/* Player data - points */
			const playerPoints = JSON.parse(JSON.stringify(playerData.points));
			/* Player data - streaks */
			const answerIsCorrect = quizData.answers[answerIndex].isCorrect;
			let streakUnlocked = false;
			let newPointsTierUnlocked = false;
			let playerStreaks: any = {};
			if (appConfig.useStreaks) {
				if (playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(playerData.streaks));
				}

				if (!playerStreaks.hasOwnProperty(`room${roomId.toString()}`)) {
					playerStreaks[`room${roomId.toString()}`] = 0;
				}
				if (
					playerStreaks[`room${roomId.toString()}`] !== -1 ||
					!appConfig.limitStreaksPerRoom
				) {
					if (answerIsCorrect) {
						playerStreaks[`room${roomId.toString()}`] =
							playerStreaks[`room${roomId.toString()}`] + 1;
					} else {
						playerStreaks[`room${roomId.toString()}`] = 0;
					}
					const roomStreak = parseInt(
						playerStreaks[`room${roomId.toString()}`]
					);
					if (roomStreak === streakPoints.streakMarker) {
						streakUnlocked = true;
						const prevPlayerPoints = playerPoints[`room${roomId.toString()}`];
						playerPoints[`room${roomId.toString()}`] =
							playerPoints[`room${roomId.toString()}`] +
							streakPoints.streakPoints;
						newPointsTierUnlocked =
							getIconType(prevPlayerPoints, roomId) !==
							getIconType(playerPoints[`room${roomId.toString()}`], roomId);
						if (appConfig.limitStreaksPerRoom) {
							playerStreaks[`room${roomId.toString()}`] = -1;
						} else {
							playerStreaks[`room${roomId.toString()}`] = 0;
						}
					}
				}
			}

			/* Update player data */
			updatePlayerData({
				...playerData,
				challenges: playerChallenges,
				streaks: playerStreaks,
				points: playerPoints,
			}).then((res) => {
				if (res.status === 'ok') {
					/* Check if all correct answers have been selected */
					const challengeIsComplete =
						quizData.answers.filter((answer: IQuizAnswer) => {
							return answer.isCorrect && answer.isSelected;
						}).length ===
						quizData.answers.filter((answer: IQuizAnswer) => {
							return answer.isCorrect;
						}).length;

					if (challengeIsComplete) {
						/* Update game state, handle challenge completed */
						setTimeout(() => {
							handleCompleteQuiz(
								streakUnlocked,
								newPointsTierUnlocked,
								quizData,
								playerChallenges,
								playerStreaks,
								playerPoints
							);
						}, 250);
					} else {
						/* Update game state, show streak popup */
						setQuizControllerState({
							...quizControllerState,
							quizData: quizData,
							isPaused: false,
						});
						if (streakUnlocked) {
							setTimeout(() => {
								toggleStreakPopup(true, false, false, newPointsTierUnlocked);
							}, 250);
						}
					}
				}
			});
		});
	};

	/**
	 * Complete quiz
	 * Check answer(s), give feedback, update player data
	 * @param {bool} streakUnlocked
	 */
	const handleCompleteQuiz = (
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		quizData: any,
		playerChallenges: any,
		playerStreaks: any,
		playerPoints: any
	) => {
		const newQuizData = { ...quizData };
		newQuizData.completed = true;

		/* Calculate points */
		const basePoints = quizPoints.basePoints;

		let wrongAnswers = 0;
		newQuizData.answers.forEach((answer: IQuizAnswer) => {
			if (!answer.isCorrect && answer.isSelected) {
				wrongAnswers = wrongAnswers + 1;
			}
		});

		const wrongtimespoints =
			wrongAnswers * quizPoints.minusPointsPerWrongAnswer;
		const points = Math.max(
			quizPoints.minPoints,
			basePoints - wrongtimespoints
		);

		/* Update player data */
		completeChallenge(
			newQuizData.id,
			points,
			streakUnlocked,
			newPointsTierUnlocked,
			playerChallenges,
			playerStreaks,
			playerPoints
		).then(() => {
			setQuizControllerState({ ...quizControllerState, quizData: newQuizData });
		});
	};

	useEffect(() => {
		// Component mounted
		// Load challenge
		loadChallenge();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setQuizControllerState(
			{ ...quizControllerState, isLoading: true, quizData: null },
			() => {
				loadChallenge();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeData.id]);

	if (!quizControllerState.isLoading && quizControllerState.quizData) {
		return (
			<Quiz
				isPaused={quizControllerState.isPaused}
				showImagePopup={quizControllerState.showImagePopup}
				quizData={quizControllerState.quizData}
				toggleImagePopup={toggleImagePopup}
				handleSelectAnswer={handleSelectAnswer}
			/>
		);
	}
	return null;
};

export default QuizController;
