/* TEMPLATE
{
	id: '',
	type: 'pairs',
	roomId: 1,
	challengeNumber: 1,
	question: {
		text: 'blah?'
	},
	columnA: [
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
	],
	columnB: [
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
	]
},
*/
export interface IPairsQuestion {
	text: string;
}
export interface IPairscolumnAItem {
	id: string;
	text: string;
	image: string | null;
	isSelected: boolean;
	connectedTo: string | null;
	correctConnectionId: string;
}
export interface IPairscolumnBItem {
	id: string;
	text: string;
	image: string | null;
	isSelected: boolean;
}
export interface IPairsData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IPairsQuestion;
	columnA: IPairscolumnAItem[];
	columnB: IPairscolumnBItem[];
	completed?: boolean;
}

const pairsData: IPairsData[] = [
	{
		id: 'pairs-1',
		type: 'pairs',
		roomId: 1,
		challengeNumber: 2,
		question: {
			text: 'Grundloven blev først vedtaget i 1849 og er blevet ændret fire gange siden. Hvilke dele af grundloven kom hvornår? Match indholdet med det rigtige årstal.'
		},
		columnA: [
			{id: '1', text: 'Magtens tredeling indføres', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1'},
			{id: '2', text: 'Kongen og godsejere får mere magt', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2'},
			{id: '3', text: 'Valgretsalder skrives ud af grundloven', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3'},
			{id: '4', text: 'Kvinder og tjenestefolk får stemmeret', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4'},
			{id: '5', text: 'Sønderjylland tilbage&shy;føres til Danmark', image: null, isSelected: false, connectedTo: null, correctConnectionId: '5'},
		],
		columnB: [
			{id: '1', text: '1849', image: null, isSelected: false},
			{id: '2', text: '1866', image: null, isSelected: false},
			{id: '3', text: '1953', image: null, isSelected: false},
			{id: '4', text: '1915', image: null, isSelected: false},
			{id: '5', text: '1920', image: null, isSelected: false},
		]
	}
];

export { pairsData };
