export interface IIcons {
	title: string;
	text?: string;
	pointsMin?: number;
	pointsMax?: number;
	id: string;
}

const icons = [
	{
		roomId: 1,
		points: [
			{ pointsMax: 4, id: 'none', title: '' },
			{ pointsMin: 5, pointsMax: 15, id: 'novice', title: 'Novice' },
			{ pointsMin: 16, pointsMax: 28, id: 'amateur', title: 'Amatør' },
			{ pointsMin: 29, pointsMax: 39, id: 'apprentice', title: 'Lærling' },
			{ pointsMin: 40, pointsMax: 52, id: 'expert', title: 'Kaptajn' },
			{ pointsMin: 53, pointsMax: 66, id: 'master', title: 'Mester' },
			{ pointsMin: 67, id: 'grand-master', title: 'Stormester' },
		],
	},
];

export { icons };
