import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {sortArrayByProperty} from 'helpers/array-helper';
import {loginUiTexts} from 'data/ui-texts';
import {roomsData} from 'data/rooms-data';
import './stats.scss';

const Stats = ({stats, handleLogout}) => {
	/* Filter by date */ 
	const filteredStats = stats.filter((s) => {
		return (s.hasOwnProperty('created') && parseInt(s.created) >= 20211025);
	});

	/* Sort by creation date */
	const sortedStats = sortArrayByProperty(filteredStats, 'created', 'ASC');
	
	/* Get earliest creation date */
	const startDate = (sortedStats.length > 0 
		? dayjs(sortedStats[0].created).toDate() : 
		null
	);

	/* Get latest creation date */
	const endDate = (sortedStats.length > 0 
		? dayjs(sortedStats[sortedStats.length - 1].created).toDate() 
		: null
	);

	/* Get total stats */
	const totalStarted = filteredStats.filter((s) => {return (s.type === 'started');}).length;
	const totalCompleted = filteredStats.filter((s) => {return (s.type === 'completed');}).length;
	const totalCompletedPercent = (totalStarted > 0
		? Math.round(((totalCompleted / totalStarted) * 100.))
		: 0
	);

	return (
		<div className="Stats">
			<div className="Stats-logoutBtn" onClick={() => {handleLogout();}}>{loginUiTexts.logout}</div>
			<div className="Stats-title">Statistik for Grundlovsdysten</div>
			<div className="Stats-period">
				{startDate && dayjs(startDate).format('DD/MM-YY')}
				{' til '} 
				{endDate && dayjs(endDate).format('DD/MM-YY')}
			</div>
			<div className="Stats-rooms">
				<div className="Stats-roomsHeader">
					<div className="Stats-roomTitle">Modul</div>
					<div className="Stats-roomStarted">Antal gange startet</div>
					<div className="Stats-roomCompleted">Antal gange afsluttet</div>	
				</div>
				{roomsData.map((roomData) => {
					const timesStarted = 
						filteredStats.filter((s) => {
							return (s.roomId === roomData.id && s.type === 'started');
						}).length;
					const timesCompleted = 
						filteredStats.filter((s) => {
							return (s.roomId === roomData.id && s.type === 'completed');
						}).length;
					const startedPercent = (totalStarted > 0 
						? Math.round((timesStarted / totalStarted) * 100.) : 0);
					const completedPercent = (timesStarted > 0 
						? Math.round((timesCompleted / timesStarted) * 100.) : 0);
					return (
						<div key={roomData.id} className="Stats-room">
							<div className="Stats-roomTitle">{roomData.name}</div>
							<div className="Stats-roomStarted">{timesStarted} ({startedPercent}%)</div>
							<div className="Stats-roomCompleted">{timesCompleted} ({completedPercent}%)</div>
						</div>
					);
				})}

				<div className="Stats-total">
					<div className="Stats-roomTitle">ALLE RUM</div>
					<div className="Stats-roomStarted">{totalStarted}</div>
					<div className="Stats-roomCompleted">{totalCompleted} ({totalCompletedPercent}%)</div>
				</div>

			</div>
		</div>
	);
};

Stats.propTypes = {
	stats: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,

};

export default Stats;