const errorUiTexts = {
	dk:{
	offline: 'Du er offline. Forbind til internettet for at spille.',
	},
	en: {
		offline: 'You are offline. Connect to the Internet to play.'
	}
};

const generalUiTexts = {
	dk: {
		gameName: 'Grundlovsdysten',
		availabilityStatement: 'Tilgængelighedserklæring',
		introText: `Hvad ved du om Danmarks Riges Grundlov? 
			<br />Få testet din viden om grundloven, som fastlægger de overordnede spilleregler for vores demokrati, retsstat og folkestyre.
			<br /><br />Tag Grundlovsdysten og se, om du er novice eller stormester.
			<br /><br />God fornøjelse!`,
		otherGames: 'Vil du udfordres yderligere, så find andre spil her.',
		otherGamesBtn: 'Gå til Domstolsdysten.dk',
		moreInfo: 'Mere info...',
		aboutTitle: 'KORT OM QUIZZEN',
		developedBy: 'Grundlovsdysten er udviklet af Domstolsstyrelsen og Copenhagen Game Lab.',
		copyright: 'Copyright 2024: Domstolsstyrelsen',
		yourScore: 'Jeres score',
		points: 'point',
		ok: 'Ok',
	},
	en: {
		gameName: 'Grundlovsdysten',
		availabilityStatement: 'Tilgængelighedserklæring',
		introText: `Hvad ved du om Danmarks Riges Grundlov? 
			<br />Få testet din viden om grundloven, som fastlægger de overordnede spilleregler for vores demokrati, retsstat og folkestyre.
			<br /><br />Tag Grundlovsdysten og se, om du er novice eller stormester.
			<br /><br />God fornøjelse!`,
		otherGames: 'Vil du udfordres yderligere, så find andre spil her.',
		otherGamesBtn: 'Gå til Domstolsdysten.dk',
		moreInfo: 'Mere info...',
		aboutTitle: 'KORT OM QUIZZEN',
		developedBy: 'Grundlovsdysten er udviklet af Domstolsstyrelsen og Copenhagen Game Lab.',
		copyright: 'Copyright 2024: Domstolsstyrelsen',
		yourScore: 'Jeres score',
		points: 'point',
		ok: 'Ok',
	}
};

const challengeUiTexts = {
	dk: {
		noChallenges: 'Der er ingen opgaver i område %roomId% endnu.',
		choose: 'Vælg',
		answers: 'svar',
		next: 'Næste',
		streakPopup: {
			title: 'Sådan!',
			text: '%answers% rigtige svar i træk:',
			title2: 'Godt gået!',
			text2:
				'<span class="red">%answers%</span> rigtige svar i træk: <span class="red">+%points% point</span>.<br />Og du har nu fået point nok til at blive',
			buttons: { ok: 'Videre' },
		},
		newPointTierPopup: {
			title: 'Godt gået!',
			text: 'Du har nu fået point nok til at blive',
			buttons: { ok: 'Ok' },
		},
		roomCompletedPopup: {
			title: [
				'Resultat: %points% ud af 77 point!',
			],
			texts: [
				'Du fik point nok til at blive:',
			],
			shareTexts: [
				'Jeg spillede Grundlovsdysten og fik point nok til at blive:',
			],
		},
	},
	en: {
		noChallenges: 'There are no tasks in area %roomId% yet.',
		choose: 'Choose',
		answers: 'answers',
		next: 'Next',
		streakPopup: {
			title: 'Yeah!',
			text: '%answers% correct answers in a row:',
			title2: 'Well done!',
			text2:
				'<span class="red">%answers%</span> correct answers in a row: <span class="red">+%points% points</span>.<br />And you have scored enough points to become a',
			buttons: { ok: 'Continue' },
		},
		newPointTierPopup: {
			title: 'Well done!',
			text: 'You obtained enough points to become a',
			buttons: { ok: 'Ok' },
		},
		roomCompletedPopup: {
			title: [
				'Resultat: %points% ud af 77 point!',
			],
			texts: [
				'Du fik point nok til at blive:',
			],
			shareTexts: [
				'Jeg spillede Grundlovsdysten og fik point nok til at blive:',
			],
		},
	}
};

const loginUiTexts = {
	loginTitle: 'Log på som admin',
	email: 'e-mail',
	password: 'password',
	login: 'Log ind',
	logout: 'Log ud',
	unknownFejl: 'Fejl: ukendt fejl',
	someFieldsMissing: 'Fejl: Husk at udfylde alle felter',
	invalidEmail: 'Fejl: Ugyldig e-mail',
	invalidPassword: 'Fejl: Forkert password',
	userNotFound: 'Fejl: Ukendt bruger'
};

export { errorUiTexts, generalUiTexts, challengeUiTexts, loginUiTexts };
