// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import appConfig from 'config/app.config';
import { generalUiTexts } from 'data/ui-texts';
import {
	IPagesComponentProps,
	IGState,
	IGActions,
	useGlobal,
} from 'components/game/game-controller';
import ddlogo from 'assets/images/logo-dd.svg';
import iconPlay from 'assets/images/icon-play.svg';
import './landing-page.scss';

const LandingPage: React.FC<IPagesComponentProps> = ({
	goToPage,
	resetGame,
}: IPagesComponentProps) => {
	const [currentlang, toggleLang] = useGlobal<
		string,
		(currentLang: string) => void
			>(
			(state: IGState) => {
				return state.currentlang;
			},
			(actions: IGActions) => {
				return actions.toggleLang;
			}
			);
	const [generalUiTextsLang, setGeneralUiTextsLang] = React.useState({...generalUiTexts.dk});
	React.useEffect(() => {
		setGeneralUiTextsLang(currentlang === 'en' ? generalUiTexts.en : generalUiTexts.dk );
	}, [currentlang]);

	return (
		<div className="LandingPage">
			<div className="LandingPage-header">
				<img src={ddlogo} alt="logo" className="LandingPage-ddLogo" />
				<div
					className={`pointer RoomOverview-chooseLanguage lang--${
						currentlang === 'en' ? 'en' : 'dk'
					}`}
					onClick={() => {
						toggleLang(currentlang === 'en' ? 'en' : 'dk');
					}}
				/>
			</div>
			<div className="LandingPage-content">
				<h1 className="LandingPage-heading">{generalUiTextsLang.gameName}</h1>
				<img
					src={iconPlay}
					alt="next"
					className="LandingPage-startBtn pointer"
					onClick={() => {
						goToPage('roomOverview');
					}}
				/>
			</div>

			{(appConfig.env === 'development' || appConfig.env === 'test') && (
				<div
					className="LandingPage-resetCacheBtn pointer"
					onClick={() => {
						resetGame();
					}}
				>
					Reset
				</div>
			)}
			<div className='LandingPage-logo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}} ></div>

			<a 
				className="LandingPage-availabilityStatementLink" 
				href={appConfig.availabilityStatementLink} 
				target="_blank" 
				rel="noopener noreferrer">
				{generalUiTextsLang.availabilityStatement}
			</a>
		</div>
	);
};

export default LandingPage;
