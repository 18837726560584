let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let url = 'http://localhost:3000';
let statsDbName = 'stats';
let firebaseConfig = {};

if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyC8RCr8MCKs6jcpWloD0Tdr85R0XXcqVAs',
		authDomain: 'cgl-grundlovsdysten-test.firebaseapp.com',
		projectId: 'cgl-grundlovsdysten-test',
		storageBucket: 'cgl-grundlovsdysten-test.appspot.com',
		messagingSenderId: '169354636074',
		appId: '1:169354636074:web:3dffe58a92d379261bcc53'
	};
}

if (env === 'test') {
	url = 'https://cgl-grundlovsdysten-test.web.app';
}
if (env === 'demo') {
	url = 'https://cgl-grundlovsdysten-demo.web.app';
	statsDbName = 'stats-demo';
}
if (env === 'production') {
	url = 'https://grundlovsdysten.dk';
	firebaseConfig = {
		apiKey: 'AIzaSyBCkmNdnmmLX9pYBzR_jBW3fJ4XKvd27-k',
		authDomain: 'cgl-grundlovsdysten-production.firebaseapp.com',
		projectId: 'cgl-grundlovsdysten-production',
		storageBucket: 'cgl-grundlovsdysten-production.appspot.com',
		messagingSenderId: '728043467884',
		appId: '1:728043467884:web:f3c7851a753bdfed7fd449'
	};
}


const appConfig = {
	env: env,
	firebaseConfig: firebaseConfig,
	name: 'Grundlovsdysten',
	url,
	statsDbName,
	useStreaks: false,
	limitStreaksPerRoom: false,
	cookiesAcceptedCookieName: 'grundlovsdysten_cookies_accepted',
	playerDataCookieName: 'grundlovsdysten_player_data',
	availabilityStatementLink: 'https://www.was.digst.dk/grundlovsdysten-dk'
};

export default appConfig;
