/* TEMPLATE
{
	id: '',
	type: 'quiz',
	roomId: 1,
	challengeNumber: 1,
	question: {
		text: '',
		showNumberOfAnswers: true,
		image: null,
		showImageInPopup: false
	},
	answers: [
		{id: 1, text: '', isSelected: false, isCorrect: false},
		{id: 2, text: '', isSelected: false, isCorrect: false},
		{id: 3, text: '', isSelected: false, isCorrect: false}
	]
},
*/
export interface IQuizQuestion {
	text: string;
	showNumberOfAnswers: boolean;
	shuffleAnswers: boolean;
	image: null;
	showImageInPopup: boolean;
}
export interface IQuizAnswer {
	id: number;
	text: string;
	isSelected: boolean;
	isCorrect: boolean;
}
export interface IQuizData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IQuizQuestion;
	answers: IQuizAnswer[];
	completed?: boolean;
}

const quizzesData: IQuizData[] = [
	{
		id: 'quiz-1',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 1,
		question: {
			text: 'Grundloven er Danmarks forfatning, altså den lov der beskriver de grundlæggende regler for samfundet. Den havde oprindeligt 100 paragraffer. Hvor mange har den nu?',
			showNumberOfAnswers: false,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: '62', isSelected: false, isCorrect: false},
			{id: 2, text: '89', isSelected: false, isCorrect: true},
			{id: 3, text: '116', isSelected: false, isCorrect: false},
			{id: 4, text: '229', isSelected: false, isCorrect: false}
		],
	},
	// {
	// 	id: 'quiz-2',
	// 	type: 'quiz',
	// 	roomId: 1,
	// 	challengeNumber: 4,
	// 	question: {
	// 		text: "Grundloven garanterer forskellige goder vi i dag vil forbinde med velfærdsstaten. Hvilke er det?",
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false,
	// 	},
	// 	answers: [
	// 		{ id: 1, text: 'Gratis skolegang', isSelected: false, isCorrect: true },
	// 		{ id: 2, text: 'Understøttelse', isSelected: false, isCorrect: true },
	// 		{ id: 3, text: 'Offentlig transport', isSelected: false, isCorrect: false },
	// 		{ id: 4, text: 'Lægebehandling', isSelected: false, isCorrect: false },
	// 		{ id: 5, text: 'Pension', isSelected: false, isCorrect: false },
	// 	],
	// },
	// {
	// 	id: 'quiz-3',
	// 	type: 'quiz',
	// 	roomId: 1,
	// 	challengeNumber: 5,
	// 	question: {
	// 		text: 'Fra 1953 fastsættes dele af valgretten - altså hvem der kan stemme til folketingsvalg - ikke af grundloven, men af valgloven. Men hvad var de oprindelige valgretskriterier, da Grundloven blev skrevet i 1849?',
	// 		showNumberOfAnswers: true,
	//		shuffleAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false,
	// 	},
	// 	answers: [
	// 		{ id: 1, text: 'Man skulle være mand', isSelected: false, isCorrect: true },
	// 		{ id: 2, text: 'Man skulle være over 30', isSelected: false, isCorrect: true },
	// 		{ id: 3, text: 'Man skulle have egen husstand', isSelected: false, isCorrect: true },
	// 		{ id: 4, text: 'Man måtte ikke modtage eller have modtaget understøttelse', isSelected: false, isCorrect: true },
	// 		{ id: 5, text: 'Man skulle være uberygtet (ustraffet)', isSelected: false, isCorrect: true },
	// 		{ id: 6, text: 'Man skulle være over 18', isSelected: false, isCorrect: false },
	// 		{ id: 7, text: 'Man skulle tjene mere end gennemsnitsindkomsten', isSelected: false, isCorrect: false },
	// 		{ id: 8, text: 'Man skulle være gift', isSelected: false, isCorrect: false },
	// 		{ id: 9, text: 'Man skulle være medlem af folkekirken', isSelected: false, isCorrect: false },
	// 	],
	// },
	{
		id: 'quiz-4',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 6,
		question: {
			text: 'Hvem har i følge Grundloven <i>ikke</i> religionsfrihed i Danmark?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Præster', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Regenten', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Statsministeren', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Folketingsmedlemmer', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Alle har religionsfrihed i Danmark', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-5',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 8,
		question: {
			text: 'Foreninger kan forbydes, selvom Grundlovens § 78 sikrer, at der er foreningsfrihed. Med hvilken begrundelse kan det ske?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: 'Hvis foreningens medlemmer laver økonomisk kriminalitet', isSelected: false, isCorrect: false},
			{id: 2, text: 'Hvis foreningens formål er kriminalitet, eller hvis den virker ved eller opfordrer til vold', isSelected: false, isCorrect: true},
			{id: 3, text: 'Hvis et flertal i Folketinget vedtager det', isSelected: false, isCorrect: false},
			{id: 4, text: 'Hvis regeringen mener, at foreningen er uforenelig med danske værdier', isSelected: false, isCorrect: false},
			{id: 5, text: 'Hvis det besluttes ved en folkeafstemning', isSelected: false, isCorrect: false},
		],
	},
	{
		id: 'quiz-6',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 10,
		question: {
			text: 'Grundloven er Danmarks Riges øverste lov, og den er ret svær at ændre. Hvad skal der til for at ændre Grundloven?',
			showNumberOfAnswers: true,
			shuffleAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: 'Folketinget skal vedtage ændringen, der skal holdes folketingsvalg, og det nye Folketing skal også vedtage den', isSelected: false, isCorrect: false},
			{id: 2, text: 'Der skal afholdes en folkeafstemning om ændringen', isSelected: false, isCorrect: false},
			{id: 3, text: 'Mindst 40% af de stemmeberettigede borgere skal stemme ja', isSelected: false, isCorrect: false},
			{id: 4, text: 'Alle ovenståede punkter', isSelected: false, isCorrect: true},
		],
	},
	{
		id: 'quiz-7',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 11,
		question: {
			text: 'Grundloven beskriver kongens ansvar/magt. Hvordan forstås det i dag?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: '”Kongen” betyder den siddende regent, og denne har udøvende magt alene, uanset om det er en konge eller en dronning', isSelected: false, isCorrect: false},
			{id: 2, text: 'Grundloven siger, at "kongen" leder landet, og det betyder i dag Statsministeren', isSelected: false, isCorrect: false},
			{id: 3, text: 'Grundloven foreskriver, at "kongen" udøver magt gennem ministrene, hvilket betyder regeringen', isSelected: false, isCorrect: true},
			{id: 4, text: 'I Danmark har vi folkestyre, så "kongen" kan oversættes til "folket"', isSelected: false, isCorrect: false},
		],
	},
	{
		id: 'quiz-8',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 12,
		question: {
			text: '§ 20 fra 1953 gør det muligt at overføre suverænitet til internationale organisationer (fx EU). Hvad kan disse organisationer gøre i Danmark?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: 'Lovgive med direkte effekt', isSelected: false, isCorrect: true},
			{id: 2, text: 'Stille lovforslag som skal afgøres ved folkeafstemning', isSelected: false, isCorrect: false},
			{id: 3, text: 'Udskrive valg', isSelected: false, isCorrect: false},
			{id: 4, text: 'Afsige dom med økonomiske sanktioner, hvis Danmark ikke implementerer EUs regler korrekt', isSelected: false, isCorrect: true},
			{id: 5, text: 'Give danske borgere fængselsstraf', isSelected: false, isCorrect: false},
		],
	},
	{
		id: 'quiz-9',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 13,
		question: {
			text: 'Regeringsprærogativer er rettigheder, som Grundloven giver kongen (nu regeringen) alene, og som ikke kan begrænses af Folketinget. Hvilke af følgende er regeringsprærogativer?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: 'Anvende militær magt mod fremmede stater, hvis Danmark bliver angrebet', isSelected: false, isCorrect: true},
			{id: 2, text: 'Anerkende andre stater', isSelected: false, isCorrect: true},
			{id: 3, text: 'Handle på Danmarks vegne i internationale anliggender', isSelected: false, isCorrect: true},
			{id: 4, text: 'Ændre landets grænser', isSelected: false, isCorrect: false},
			{id: 5, text: 'Benåde borgere og give amnesti', isSelected: false, isCorrect: true},
			{id: 6, text: 'Pålægge staten nye udgifter', isSelected: false, isCorrect: false},
			{id: 7, text: 'Bestemme hvilke lovforslag, der behandles i Folketinget', isSelected: false, isCorrect: false}
		],
	},
	{
		id: 'quiz-10',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 14,
		question: {
			text: 'Grundloven gælder alle dele af Danmarks rige. Den nævner også Færøerne, Grønland og Island, som i 1849 var en del af det danske rigsfællesskab. Gælder grundloven de tre steder?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: 'Ja', isSelected: false, isCorrect: false},
			{id: 2, text: 'Nej, de har egne grundlove', isSelected: false, isCorrect: false},
			{id: 3, text: 'Grundloven gælder i Grønland og på Færøerne, men ikke i Island', isSelected: false, isCorrect: true},
			{id: 5, text: 'Kun i Grønland og på Færøerne hele året, men på Island om vinteren af historiske årsager', isSelected: false, isCorrect: false},
		],
	},
	{
		id: 'quiz-11',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 15,
		question: {
			text: 'Hvilke rettigheder har man i følge grundloven, når man bliver anholdt?',
			showNumberOfAnswers: true,
			shuffleAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{id: 1, text: 'Man skal stilles for en dommer indenfor 24 timer efter anholdelsen', isSelected: false, isCorrect: true},
			{id: 2, text: 'Politiet skal have foretaget en afhøring af den anholdte, inden man kommer i fængsel', isSelected: false, isCorrect: false},
			{id: 3, text: 'Frihedsberøvelse over 24 timer kan kun ske med dommerkendelse', isSelected: false, isCorrect: true},
			{id: 4, text: 'Der må ikke være advokater til stede under et grundlovsforhør, da det kun er dommeren, der må tale', isSelected: false, isCorrect: false},
			{id: 5, text: 'Politiet kan kun frihedsberøve folk, hvis de har ret til det ifølge loven', isSelected: false, isCorrect: true},
		],
	},
];

export { quizzesData };
